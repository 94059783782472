.work {
    height: 100%;
    margin-bottom: 100px;
    margin-top: 50px;

    @media (max-width: 1024px) {
        margin-bottom: 50px;
    }

    h2 {
        font-size: 46px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $color-tamarind;
        margin: 0;

        @media (max-width: 1024px) {
            font-size: 30px;
        }
    }

    h3 {
        opacity: 0.5;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $color-tamarind;
        margin: 0;

        @media (max-width: 1024px) {
            font-size: 18px;
        }
    }

    &__process {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 80px;

        @media (max-width: 1024px) {
            margin-top: 30px;
            flex-wrap: wrap;
            justify-content: left;
        }
    }

    &__item {
        width: 20%;

        @media (max-width: 1024px) {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: flex-start;
            margin-bottom: 30px;
            width: 100%;
        }

        img {
            margin-bottom: 20px;

            @media (max-width: 1024px) {
                display: inline-block;
                width: 70px;
                padding-right: 20px;
            }
        }

        &_info {
            padding-right: 40px;
            border-top: dashed 2px #3dded5;

            @media (max-width: 1024px) {
                border: 0;
                display: inline-block;
                padding-right: 0;
                width: calc(100% - 110px);
            }
        }

        &:last-child {
            .work__item_info {
                padding-right: 0;
            }
        }

        &_step {
            opacity: 0.5;
            font-size: 16px;
            font-weight: bold;

            @media (max-width: 1024px) {
                margin: 0;
            }
        }

        &_title {
            font-size: 20px;
            font-weight: bold;

            @media (max-width: 1024px) {
                margin: 0;
                margin-top: 8px;
                font-size: 18px;
            }
        }

        &_text {
            opacity: 0.5;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $color-tamarind;
            margin-bottom: 0;
        }
    }
}