.debt {
    display: flex;
    margin-top: 100px;
    margin-bottom: 100px;

    @media (max-width: 1024px) {
        flex-wrap: wrap;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &__left {
        width: 35%;
        margin-right: 10%;

        @media (max-width: 1024px) {
            width: 100%;
            margin-right: 0;
        }

        h4 {
            opacity: 0.7;
            font-size: 16px;
            font-weight: bold;
            color: #1f0b19;
            margin: 0;
        }

        h3 {
            font-size: 56px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.07;
            letter-spacing: normal;
            color: #1f0b19;
            margin: 0;

            @media (max-width: 1024px) {
                font-size: 45px;
            }
        }

        p, 
        a {
            opacity: 0.7;
            font-size: 16px;
            line-height: 1.5;
            color: #1f0b19;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__right {
        width: 45%;

        @media (max-width: 1024px) {
            width: 100%;
        }
    }

    &__item {
        position: relative;
        margin: 0;
        padding: 10px 0;
        font-size: 24px;
        line-height: 1.42;
        color: #1f0b19;
        border-bottom: 1px dashed $color-turquoise;
        padding-left: 40px;

        @media (max-width: 1024px) {
            font-size: 20px;
        }

        &:first-child {
            border-top: 1px dashed $color-turquoise;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 15px;
            background-image: url('./../images/debtIcon.svg');
            background-size: 22px;
            width: 21px;
            height: 26px;
        }
    }
}
