footer {
    background-color: $color-turquoise3;
    width: 100%;
    height: 100%;

    .footer {
        height: 141px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        @media (max-width: 1024px) {
            height: 100px;
        }

        a {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $color-tamarind;
            text-decoration: none;
        }

        p {
            margin: 0;
            opacity: 0.5;
            font-size: 16px;
            color: $color-tamarind;
        }
    }
}
