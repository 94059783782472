.calculator {
    padding-top: 100px;
    padding-bottom: 150px;
    
    h3 {
        text-align: center;
        font-size: 35px;
        font-weight: bold;
        color: #1f0b19;
    }

    &__block {
        display: flex;
        justify-content: center;
        margin-bottom: 36px;

        @media (max-width: 1024px) {
            flex-wrap: wrap;
        }
    }

    &__type {
        display: flex;
        justify-content: center;
        margin-bottom: 36px;

        @media (max-width: 1024px) {
            flex-wrap: wrap;
        }

        &_step {
            cursor: pointer;
            text-align: center;
            padding: 16px 20px;
            min-width: 355px;

            @media (max-width: 1024px) {
                min-width: calc(100% - 32px);
            }

            &:hover {
                color: #3dded5;
            }
        }

        &_active {
            background-color: $color-turquoise5;

            &:hover {
                color: #384048;
            }
        }
    }

    &__item {
        position: relative;
        cursor: pointer;
        min-height: 300px;

        @media (max-width: 1024px) {
            min-height: auto;
        }

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: -5px;
            right: -5px;
            border-style: solid;
            border-width: 0 50px 50px 0;
            border-color: transparent #fff transparent transparent;
        }

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: -2px;
            right: -2px;
            border-style: solid;
            border-width: 0 50px 50px 0;
            border-color: transparent $color-turquoise4 transparent transparent;
        }
    }

    &__item_active {
        cursor: default;
    }

    &__item_order {
        display: none;
    }

    &__item_active {
        .calculator__item_order {
            display: block;
        }
    }

    &__item,
    &__total {
        width: 353px;
        margin: 20px;
        padding: 30px;
        padding-bottom: 20px;
        border: solid 4px $color-turquoise4;

        @media (max-width: 1024px) {
            width: 100%;
            margin: 0;
            margin-bottom: 20px;
            padding: 27px;
        }

        &_title {
            font-size: 24px;
            font-weight: bold;
            line-height: normal;
            color: $color-turquoise7;
            margin: 0;
            margin-bottom: 20px;

            @media (max-width: 1024px) {
                font-size: 21px;
                margin-bottom: 0;
            }
        }

        &_subtitle {
            opacity: 0.5;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1f0b19;
            margin: 0;
            margin-bottom: 15px;
        }

        &_total {
            font-size: 24px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1f0b19;
            margin: 0;
            margin-bottom: 18px;

            input {
                border: 0;
                width: 100%;
                outline: none;
                max-width: 200px;
            }
        }



        &:hover,
        &:active,
        &_active {
            border: solid 4px #3dded5;

            &::after {
                border-color: transparent #fff transparent transparent;
            }
    
            &::before {
                border-color: transparent #3dded5 transparent transparent;
            }

            @media (max-width: 1024px) {
                .calculator__item_title {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &__total {
        border-color: transparent;
        background-color: #3dded5;

        &_title {
            opacity: 0.8;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            margin: 0;
            margin-bottom: 5px;
        }

        &_cost {
            font-size: 42px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            margin: 0;
            margin-bottom: 40px;

            @media (max-width: 1024px) {
                margin-bottom: 20px;
            }
        }

        &_text {
            font-size: 17px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            margin: 0;
            margin-top: 60px;

            @media (max-width: 1024px) {
                margin-top: 0;
            }
        }
    }
}
