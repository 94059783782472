.menu {
    position: fixed;
    width: calc(100% - 100px);
    background-color: $color-white;   
    visibility: hidden;
    padding: 50px;
    height: 100%;

    .wrap {
        height: 100%;

        @media (max-width: 1024px) {
            padding: 0;
            width: 100%;
        }
    }

    &__left {
        width: 55%;

        @media (max-width: 1024px) {
            width: 100%;
            margin-top: 20px;
        }
    }

    &__right {
        width: 45%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media (max-width: 1024px) {
            width: 100%;
            margin-top: -40px;
        }

        img {
            width: 100%;;
        }
    }

    &__content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        top: -50px;

        @media (max-width: 1024px) {
            flex-wrap: wrap;
            top: -30px;
        }
    }

    &__item {
        font-size: 42px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;

        @media (max-width: 1024px) {
            font-size: 20px;
            margin: 15px 0;
        }

        a {
            text-decoration: none;
            color: $color-tamarind;

            &:hover {
                text-decoration: none;
                color: $color-turquoise;
            }
        }
    }

    &__buttons {
        position: relative;
        top: -40px;

        @media (max-width: 1024px) {
            top: -10px;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;

            .button {
                font-size: 20px;
                padding: 13px 20px;
            }
        }
    }

    &__phone {
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $color-tamarind;
        margin-top: 25px;

        a {
            color: $color-turquoise;
        }
    }
}

.is-active {
    z-index: $z-index-menu;
    visibility: visible;
}
