@import './normalize.scss';
@import './variables.scss';

@import './header/header.scss';
@import './menu/menu.scss';
@import './main/main.scss';
@import './work/work.scss';
@import './start/start.scss';
@import './debt/debt.scss';
@import './calculator/calculator.scss';
@import './price/price.scss';
@import './faq/faq.scss';
@import './slider/slider.scss';
@import './footer/footer.scss';

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  color: $color-tamarind;
}

.wrap {
  max-width: 1140px;
  margin: 0 auto;
  width: calc(100% - 40px);
  padding: 0 20px;
}

p,
span,
ul,
div,
li {
  color: $color-tamarind;
}

.button {
  background-color: #3dded5;
  padding: 13px 50px;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border: 0;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: none;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}


/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
  top: 3px;
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: .9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */
  
  /* most of the rest of this is opinion */
  font-family: 'IBM Plex Sans', sans-serif;
  text-align: left;
  
  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 270px;
  max-width: 270px;
  overflow: hidden;
  padding: 15px;
  background: #19405e;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  opacity: 1;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}