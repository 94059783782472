$color-white: #fff;
$color-tamarind: #1f0b19;
$color-turquoise: #3dded5;
$color-turquoise2: rgba(61, 222, 213, 0.15);
$color-turquoise3: rgba(25, 64, 94, 0.1);
$color-turquoise4: rgba(31, 11, 25, 0.1);
$color-turquoise5: rgba(61, 222, 213, 0.3);
$color-turquoise6: rgba(255, 255, 255, 0.5);
$color-turquoise7: rgba(31, 11, 25, 0.7);
$color-turquoise8: rgba(255, 255, 255, 0.7);

$z-index-header: 10;
$z-index-menu: 9;
