header {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 40px);
    padding: 5px 20px;
    background-color: #fff;
    z-index: $z-index-header;
}


.hamburger {
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px;
    padding-left: 0;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
}

.hamburger.is-active:hover, 
.hamburger:hover {
    opacity: .7;
}

.hamburger-box {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    top: -3px;
}

.hamburger-inner, 
.hamburger-inner:after, 
.hamburger-inner:before {
    position: absolute;
    width: 40px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: $color-turquoise;
}

.hamburger-inner:after, 
.hamburger-inner:before {
    display: block;
    content: "";
}

.hamburger-inner:after {
    bottom: -10px;
}
.hamburger-inner:before {
    top: -10px;
}

.hamburger--squeeze .hamburger-inner:after {
    transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
}

.hamburger.is-active .hamburger-inner, 
.hamburger.is-active .hamburger-inner:after, 
.hamburger.is-active .hamburger-inner:before {
    background-color: $color-turquoise;
}

.hamburger--squeeze.is-active .hamburger-inner:after {
    bottom: 0;
    transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
    transform: rotate(-90deg);
}

.hamburger-inner, 
.hamburger-inner:after, 
.hamburger-inner:before {
    position: absolute;
    width: 40px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: $color-turquoise;
}

.hamburger--squeeze .hamburger-inner:before {
    transition: top 75ms ease .12s,opacity 75ms ease;
}

.hamburger--squeeze.is-active .hamburger-inner:before {
    top: 0;
    transition: top 75ms ease,opacity 75ms ease .12s;
    opacity: 0;
}

.hamburger-inner {
    top: 50%;
    display: block;
    margin-top: -2px;
}

.hamburger--squeeze .hamburger-inner {
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: 75ms;
}

.hamburger--squeeze.is-active .hamburger-inner {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transform: rotate(45deg);
}