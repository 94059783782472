.slidecontainer {
    position: relative;
    width: 100%;
  }
  
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    border-bottom: dashed 2px #3dded5;
    height: 0px;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #3dded5;
    border: solid 3px #ffffff;
    outline: solid 3px #3dded5;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {

  }