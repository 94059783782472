.faq {
    display: flex;
    margin-bottom: 100px;

    @media (max-width: 1024px) {
        flex-wrap: wrap;
        margin-bottom: 50px;
    }

    &__wrap {
        margin-top: 100px;

        @media (max-width: 1024px) {
            margin-top: 60px;
        }
    }

    &__title {
        font-size: 46px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 80px;

        @media (max-width: 1024px) {
            font-size: 30px;
            margin-top: 50px;
            margin-bottom: 30px;
            text-align: left;
        }
    }

    &__left {
        width: 46%;
        margin-right: 8%;

        @media (max-width: 1024px) {
            width: 100%;
            margin-right: 0;
        }
    }

    &__right {
        width: 46%;

        @media (max-width: 1024px) {
            width: 100%;
        }
    }

    &__item {
        margin-bottom: 40px;

        &_title {
            font-size: 24px;
            font-weight: bold;
            color: #35c4bd;
            margin-bottom: 16px;
            margin-top: 0;
        }

        &_text {
            opacity: 0.8;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1f0b19;
        }
    }
}