.main {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
        flex-wrap: wrap;
        align-content: center;
    }

    &__left {
        width: 40%;
        z-index: 1;

        @media (max-width: 1024px) {
            width: 100%;
        }

        h1 {
            font-size: 66px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.06;
            letter-spacing: normal;
            color: $color-tamarind;

            @media (max-width: 1024px) {
                font-size: 50px;
                text-align: center;
            }

            @media (max-width: 320px) {
                font-size: 30px;
                padding-top: 10px;
            }
        }
    
        h2 {
            opacity: 0.7;
            font-size: 30px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1f0b19;

            @media (max-width: 1024px) {
                font-size: 22px;
                text-align: center;
            }
        }
    }

    &__right {
        width: 60%;

        @media (max-width: 1024px) {
            width: 100%;
            margin-top: 20px;
        }

        img {
            width: 100%;
        }
    }
}
