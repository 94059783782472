.price {
    height: 489px;
    background-color: #19405e;

    @media (max-width: 1024px) {
        height: 100%;
    }

    &__block {
        display: flex;
        justify-content: center;
        padding-top: 80px;
        padding-bottom: 70px;

        @media (max-width: 1024px) {
            flex-wrap: wrap;
            padding-top: 50px !important;
            padding-bottom: 0px;
        }
    }

    &__item {
        position: relative;
        width: calc(50% - 64px);
        padding: 100px 0;
        margin-right: 64px;

        @media (max-width: 1024px) {
            width: 100%;
            margin: 0;
            padding: 60px;
            margin-bottom: 60px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-image: url('./../images/angle-top.svg');
            background-size: 56px;
            background-repeat: no-repeat;
            width: 56px;
            height: 56px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            background-image: url('./../images/angle-bottom.svg');
            background-size: 35px;
            background-repeat: no-repeat;
            width: 35px;
            height: 35px;
        }

        &_title {
            margin: 0;
            margin-bottom: 14px;
            opacity: 0.8;
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
        }

        &_cost {
            margin: 0;
            margin-bottom: 17px;
            font-size: 42px;
            font-weight: 600;
            color: #ffffff;
        }

        &_text {
            margin: 0;
            font-size: 16px;
            line-height: 1.38;
            color: $color-turquoise8;

            a {
                text-decoration: underline;
                color: $color-turquoise8;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}