.start {
    width: 100%;
    height: 300px;
    background-color: $color-turquoise2;
    display: flex;
    align-items: center;

    &__block {
        position: relative;
        display: flex;
        justify-content: center;

        &::before {
            content: '';
            position: absolute;
            top: 10px;
            left: 50px;
            background-image: url('./../images/start.svg');
            background-size: 192px;
            background-repeat: no-repeat;
            width: 234px;
            height: 176px;

            @media (max-width: 1024px) {
                display: none;
            }
        }
    }

    &__title {
        font-size: 42px;
        font-weight: bold;
        color: #1f0b19;
        margin: 0;
        margin-bottom: 6px;
        text-transform: uppercase;

        @media (max-width: 1024px) {
            font-size: 36px;
        }
    }

    &__text {
        margin: 0;
        margin-bottom: 26px;
        font-size: 18px;
        color: #1f0b19;
        opacity: 0.5;
        line-height: normal;

        a {
            color: #1f0b19;
            text-decoration: none; 
        }
    }

    &__buttons {
        display: flex;
    }

    &__phone {
        position: relative;
        margin-left: 20px;
        width: 56px;
        height: 60px;
        display: block;
        background-color: #ffffff;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: 20px;
            left: 19px;
            background-image: url('./../images/phone.svg');
            background-size: 20px;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
        }
    }
}